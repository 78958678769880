import { useCoupleUserContext } from '@/context/cs/session'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React, { useEffect } from 'react'

// == GTM 設定 ===========================================================================
export type GoogleTagManagerId = `GTM-${string}`

type Props = {
  googleTagManagerId: GoogleTagManagerId
}

const GoogleTagManager: React.FC<Props> = ({ googleTagManagerId }) => (
  <Script
    id="gtm"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${googleTagManagerId}');
      `,
    }}
  />
)

export default GoogleTagManager

// == Couple ===========================================================================
/**
 * カップルの計測
 */
type PageViewCoupleProps = {
  original_screen_name: string
  guest_response_id?: string
  /** その他、個別のイベントで送りたいものはevent_optionに設定する */
  event_option?: {
    partner_invitation_status?: string
    connect_type?: 'all_guest' | 'one_guest'
  }
} & CoupleLogCommonProps
export const loggingCouplePageView = (props: PageViewCoupleProps) => {
  loggingCommonPageView({ ...props })
}

export type CustomEventCoupleProps = {
  event: string
  is_success?: isSuccess
  /** その他、個別のイベントで送りたいものはここに設定する */
  event_option?: any
} & CoupleLogCommonProps

export const loggingCoupleCustomEvent = (props: CustomEventCoupleProps) => {
  loggingCommonCustomEvent({ ...props })
}

export type CoupleLogCommonProps = {
  cs_userid?: string
  party_id?: string
  invi_id?: string
  cl_cd?: string
  guest_response_id?: string
  guest_id?: string
}

export const LoggingErrorPageView = ({
  original_screen_name,
}: Pick<PageViewCoupleProps, 'original_screen_name'>) => {
  const router = useRouter()
  const { invitationUUID, partyUUID } = router.query
  const { user } = useCoupleUserContext()
  useEffect(() => {
    if (router.isReady) {
      loggingCouplePageView({
        original_screen_name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2322 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
        cs_userid: user ? user.coupleUUID : null,
        invi_id: invitationUUID as string,
        party_id: partyUUID as string,
      })
    }
  }, [router.isReady])
  return <></>
}

export const GUEST_LIST_UP_COUPLE_EVENT_OPTION_REF = {
  LINK_MAIL_COMPLETED: 'link_mail_completed',
} as const
export type GuestListUpCoupleEventOptionRef =
  typeof GUEST_LIST_UP_COUPLE_EVENT_OPTION_REF[keyof typeof GUEST_LIST_UP_COUPLE_EVENT_OPTION_REF]

// == Couple ゲストリストアップ ===========================================================
type PageViewCoupleGuestListUpProps = {
  original_screen_name: string
  guestlist_cs_userid?: string
  cl_cd?: string
  event_option?: {
    session?: 'first' | 'second'
    ref?: GuestListUpCoupleEventOptionRef
  }
}
/**
 * ゲストリストアップ画面のページビューイベント
 * @param props
 */
export const loggingCoupleGuestListUpPageView = (props: PageViewCoupleGuestListUpProps) => {
  loggingCommonPageView({ ...props })
}

type CustomEventCoupleGuestListUpProps = {
  event: string
  guestlist_cs_userid?: string
  cl_cd?: string
  is_success?: isSuccess
  event_option?: any
}
/**
 * ゲストリストアップ画面のページビュー以外のカスタムイベント
 * @param props
 */
export const loggingCoupleGuestListUpCustomEvent = (props: CustomEventCoupleGuestListUpProps) => {
  loggingCommonCustomEvent({ ...props })
}

// == Client ===========================================================================
/**
 * クライアントの計測
 */
type PageViewClientCommonProps = {
  original_screen_name: string
  cl_userid?: string
  party_id?: string
  invi_id?: string
  cl_cd?: string
}
export const loggingClientPageView = (props: PageViewClientCommonProps) => {
  loggingCommonPageView({ ...props })
}

type CustomEventClientProps = {
  event: string
  cl_userid?: string
  party_id?: string
  invi_id?: string
  cl_cd?: string
  guest_response_id?: string
  is_success?: isSuccess
  event_option?: any
}
export const loggingClientCustomEvent = (props: CustomEventClientProps) => {
  loggingCommonCustomEvent({ ...props })
}

// == Guest ===========================================================================
/**
 * ゲストの計測
 */
type PageViewGuestProps = {
  original_screen_name: string
  party_id?: string
  invi_id?: string
  cl_cd?: string
  guest_response_id?: string
  guest_id?: string
  ref?: string
  /** その他、個別のイベントで送りたいものはevent_optionに設定する */
  event_option?: {
    payment_type?: string | null
    payment_deadline?: string | null
  }
}
export const loggingGuestPageView = (props: PageViewGuestProps) => {
  loggingCommonPageView({ ...props })
}

type CustomEventGuestProps = {
  event: string
  party_id?: string
  invi_id?: string
  cl_cd?: string
  guest_response_id?: string
  guest_id?: string
  is_success?: isGuestPasswordSuccess
  /** その他、個別のイベントで送りたいものはここに設定する */
  event_option?: {
    amount?: number
    attendance?: string
    which_guest?: string | null
    name_exist?: boolean
    name_kana_exist?: boolean
    sex?: string | null
    post_code_exist?: boolean | null
    address_exist?: boolean | null
    email_address_exist?: boolean
    tel_exist?: boolean | null
    // FIXME: 今後null以外になることはないため、ログ変更の連携をした上で除却する
    allergy_text_exist?: boolean | null
    message_exist?: boolean | null
    companion_count?: number | null
    upload_image_count?: number | null
    payment_type?: string
  }
}
export const loggingGuestCustomEvent = (props: CustomEventGuestProps) => {
  loggingCommonCustomEvent({ ...props })
}

// == Entry ===========================================================================
/**
 * 申し込みフォームの計測
 */
type PageViewEntryProps = {
  original_screen_name: string
  cl_userid?: string
  cl_cd?: string
  cl_s_cd?: string
  houjin_cd?: string
  ref?: string | Array<string>
  event_option?: {
    all_cl_count?: number
    entried_cl_count?: number
    error_code?: string
  }
}
export const loggingEntryPageView = (props: PageViewEntryProps) => {
  loggingCommonPageView({ ...props })
}

type CustomEventEntryProps = {
  event: string
  cl_userid?: string
  cl_cd?: string
  cl_s_cd?: string
  houjin_cd?: string
  is_success?: isSuccess
}
export const loggingEntryCustomEvent = (props: CustomEventEntryProps) => {
  loggingCommonCustomEvent({ ...props })
}

// == 共通 ===========================================================================
export const LOG_EVENT_STATUS = {
  SUCCESS: '10',
  FAILED: '20',
  FAILED_OTHER: '21',
} as const

// 招待状パスワード入力時、10: 成功/20: パスワードエラー/21: その他のエラー
export type isGuestPasswordSuccess = typeof LOG_EVENT_STATUS[keyof typeof LOG_EVENT_STATUS]

/**
 * 成否
 * 10が成功、20が失敗
 * */
export type isSuccess = Exclude<isGuestPasswordSuccess, '21'>

type CommonPageViewProps = {
  original_screen_name: string
}
type LoggingCommonPageViewProps =
  | PageViewClientCommonProps
  | PageViewCoupleProps
  | PageViewCoupleGuestListUpProps
  | PageViewGuestProps
  | PageViewEntryProps
  | CommonPageViewProps
export const loggingCommonPageView = (props: LoggingCommonPageViewProps) => {
  loggingActivity({ event: 'track_pageview', ...props })
}

type LoggingCommonCustomEventProps =
  | CustomEventClientProps
  | CustomEventCoupleProps
  | CustomEventCoupleGuestListUpProps
  | CustomEventGuestProps
  | CustomEventEntryProps
export const loggingCommonCustomEvent = (props: LoggingCommonCustomEventProps) => {
  loggingActivity({ ...props })
}
type PageViewEvent = { event: 'track_pageview' }
type LoggingActivityProps =
  | (LoggingCommonPageViewProps & PageViewEvent)
  | LoggingCommonCustomEventProps
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore TS2352 新規コードの strict check を有効化したいため既存のエラーは一旦 ignore している
const defaultLoggingActivityProps = {
  event: undefined,
  original_screen_name: undefined,
  cl_userid: undefined,
  cs_userid: undefined,
  guest_response_id: undefined,
  guest_id: undefined,
  party_id: undefined,
  invi_id: undefined,
  cl_cd: undefined,
  cl_s_cd: undefined,
  houjin_cd: undefined,
  event_option: undefined,
  ref: undefined,
  is_success: undefined,
} as LoggingActivityProps
const loggingActivity = (props: LoggingActivityProps) => {
  // GTM側でパラメータを未指定にしたい場合は undefined である必要があるので
  // null を明示的に undefined に置き換える
  const replacedProps = Object.fromEntries(
    Object.entries(props).map(([key, val]) => [key, val === null ? undefined : val]),
  )
  // props で未指定のパラメータも明示的に undefined に置き換えるため、 defaultLoggingActivityProps で初期化
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // dataLayerの型定義がないためエラーになる。一旦無視することで対応
  window.dataLayer.push({ ...defaultLoggingActivityProps, ...replacedProps })
}
